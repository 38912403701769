@import '../../styles/utils/__utils';

.result {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);

  transition: 0.2s;
  &:not(.active) {
    pointer-events: none;

    opacity: 0;
    .wrapper {
      transform: translateY($spacing-large);
    }
  }
  .wrapper {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: $spacing-large;
    max-width: $modal-width;

    border-radius: 5px;

    background-color: $color-white;
    box-shadow: $box-shadow;

    transition: 0.2s;
    .article {
      width: 100%;

      background-image: url('/assets/bg.png');
      background-repeat: repeat;
      background-position: center center;
      box-shadow: $box-shadow;

      transform: scale(1.05) rotate(1.5deg);
      .content {
        padding: $spacing-large $spacing-x-large;
        .logo {
          margin-bottom: $spacing-medium;
          max-width: 120px;
          max-height: 30px;
        }
        .title {
          font-family: 'Newsreader', serif;
          font-size: $font-size-x-large;

          line-height: 1em;
        }
        .description {
          margin-bottom: 0;

          font-size: $font-size-small;

          line-height: 1.4em;
        }
      }
    }

    .meta {
      display: flex;
      flex-direction: column;

      padding: $spacing-medium;
      padding-top: 0;
      width: 100%;

      font-size: $font-size-medium;

      .ranking {
        display: flex;
        justify-content: space-between;

        padding: $spacing-large $spacing-large;
        padding-bottom: $spacing-x-small;
        width: 100%;
        .stat {
          &:last-child {
            text-align: right;
          }
          .name {
            font-size: $font-size-small;
          }
          .value {
            font-size: $font-size-x-large;
            font-weight: bold;
          }
        }
      }

      .times {
        display: flex;
        justify-content: space-around;
        align-items: stretch;

        margin: $spacing-large $spacing-small;
        margin-bottom: $spacing-medium;
        padding: $spacing-small $spacing-large;

        border-radius: 5px;

        background-color: $color-gray;
        .time {
          .label {
            margin-bottom: $spacing-x-small;

            font-size: $font-size-small;

            line-height: 1em;
          }
          .value {
            font-size: $font-size-large;
            font-weight: bold;

            line-height: 1em;
          }
        }
        .separator {
          width: 1px;

          opacity: 0.2;

          background-color: $color-black;
        }
      }

      .streak {
        display: flex;
        justify-content: center;

        font-size: $font-size-small;
        font-weight: bold;

        animation-name: pulse;
        animation-duration: 1s;
        animation-iteration-count: infinite;

        animation-fill-mode: forwards;

        @keyframes pulse {
          0% {
            transform: scale(0.8);
          }

          50% {
            transform: scale(1);
          }
          100% {
            transform: scale(0.8);
          }
        }
      }

      .score {
        margin-bottom: $spacing-medium;

        font-size: $font-size-medium;

        text-align: center;
        .dayScore {
          .points {
            font-weight: bold;
          }
        }
        .deletion {
          font-size: $font-size-x-small;

          opacity: 0.6;
        }
      }

      .buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
