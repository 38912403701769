// Fonts -----------------------------------------------------------------------
$root-font-family: 'Open Sans', sans-serif;
$root-font-size: 100%;
$root-line-height: 1.5;
$root-font-weight: 400;

// Breakpoint ------------------------------------------------------------------
$breakpoint-max: 1680px;
$breakpoint-large: 1080px;
$breakpoint-medium: 900px;
$breakpoint-mobile: 900px;
$breakpoint-small: 768px;
$breakpoint-tiny: 350px;

// Page sizing -----------------------------------------------------------------
$text-width: 600px;
$modal-width: 500px;
$page-width: 1700px;

// Colors ----------------------------------------------------------------------
$color-black: #041f2d;
$color-white: #ffffff;
$color-gray: #f6f6f6;
$color-green: #45e485;
$color-red: #dd3838;

// Effects ----------------------------------------------------------------------
$box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
$blur: blur(10px);

// Font-size -------------------------------------------------------------------
$font-size-x-small: 14px;
$font-size-small: 16px;
$font-size-basis: 18px;
$font-size-medium: 20px;
$font-size-large: 26px;
$font-size-x-large: 34px;
$font-size-xx-large: 48px;

// Spacing ---------------------------------------------------------------------
$spacing-xx-small: 4px;
$spacing-x-small: 8px;
$spacing-small: 16px;
$spacing-medium: 24px;
$spacing-large: 32px;
$spacing-x-large: 48px;
$spacing-xx-large: 56px;
