@import '../../styles/utils/__utils';

.result {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.5);

  transition: 0.2s;
  &:not(.active) {
    pointer-events: none;

    opacity: 0;
    .wrapper {
      transform: translateY($spacing-large);
    }
  }
  .wrapper {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: $spacing-small;
    padding: $spacing-large;
    width: 100%;
    max-width: $modal-width;

    border-radius: 5px;

    background-color: $color-white;
    box-shadow: $box-shadow;

    transition: 0.2s;
    .content {
      @media (max-width: $breakpoint-medium) {
        h1 {
          font-size: $font-size-large;

          line-height: 1.2em;
        }
      }
    }
  }
}
