@import '../../styles/utils/__utils';

.loading {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  z-index: 10;

  color: $color-black;

  background-color: $color-white;

  transition: 0.2s;
  &:not(.active) {
    pointer-events: none;

    opacity: 0;
  }
  .animation {
    margin-bottom: $spacing-small;
  }
  .status {
    font-size: $font-size-medium;
  }
}
