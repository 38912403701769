@import '../../styles/utils/__utils';

.game {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  padding: $spacing-medium;
  padding-bottom: calc(#{$spacing-xx-large} * 2);
  min-height: 100vh;

  overflow-x: hidden;
  &.blur {
    filter: $blur;
  }
  &.dragging {
    cursor: grabbing;
  }
  .clock {
    position: absolute;
    top: 0;
    left: 0;

    padding: $spacing-medium;
    width: 100%;

    font-weight: bold;

    color: $color-black;
    text-align: center;

    opacity: 0.3;
  }
  .bank {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;

    margin: $spacing-x-large 0;
    min-height: 40vh;

    .word {
      position: relative;

      display: inline-block;

      margin: $spacing-small;
      padding: $spacing-x-small $spacing-medium;

      cursor: grab;

      user-select: none;
      font-size: $font-size-xx-large;

      transition: 0.2s;
      &.dragging {
        opacity: 0.5;
      }
      @media (max-width: $breakpoint-max) {
        font-size: $font-size-x-large;
      }
      @media (max-width: $breakpoint-large) {
        font-size: $font-size-large;
      }
      @media (max-width: $breakpoint-small) {
        font-size: $font-size-medium;
      }
    }
  }
  .inputs {
    position: relative;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .input {
      position: relative;

      display: flex;
      justify-content: center;

      padding: $spacing-small;
      padding-bottom: $spacing-x-large;
      width: 100%;
      max-width: 200px;

      @media (max-width: $breakpoint-small) {
        padding: $spacing-medium $spacing-x-large;
        width: auto;
        max-width: none;
      }
      &.over {
        .marker {
          transform: scale((2));
        }
        .inputWord {
          opacity: 0;
        }
      }
      .marker {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 60px;
        height: 60px;

        pointer-events: none;

        border-radius: 100%;

        background-color: $color-gray;

        transition: 0.2s;
        @media (max-width: $breakpoint-small) {
          padding: 0 15px;
          width: 50px;
          height: 50px;
        }
      }
      .inputWord {
        position: absolute;

        padding: $spacing-x-small $spacing-medium;

        cursor: grab;

        user-select: none;
        font-size: $font-size-x-large;

        transition: 0.2s;
        @media (max-width: $breakpoint-max) {
          font-size: $font-size-large;
        }
        @media (max-width: $breakpoint-large) {
          font-size: $font-size-large;
        }
        @media (max-width: $breakpoint-small) {
          font-size: $font-size-medium;
        }
      }
    }
  }

  .skip {
    position: absolute;
    bottom: $spacing-large;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    .clearButton {
      margin-bottom: $spacing-small;

      cursor: pointer;

      text-decoration: underline;

      opacity: 0.3;
      border: none;

      background-color: transparent;
    }
  }

  .status {
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    pointer-events: none;

    opacity: 0;

    &.active {
      pointer-events: auto;

      opacity: 1;
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 90px;
        height: 90px;

        border-radius: 100%;

        background-color: $color-gray;
        box-shadow: $box-shadow;

        animation-name: status;
        animation-duration: 0.5s;
        animation-iteration-count: 1;

        animation-fill-mode: forwards;
        @keyframes status {
          0% {
            transform: scale(0) rotate(0deg);
          }
          100% {
            transform: scale(1) rotate(360deg);
          }
        }
        &.win {
          fill: $color-green;
        }
        &.loss {
          fill: $color-red;
        }
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: space-between;

    padding: $spacing-x-small;
    width: 100%;

    font-size: 10px;

    opacity: 0.3;
    .delete {
      cursor: pointer;

      border: none;

      background-color: transparent;
    }
  }
}

.inputWord,
.word {
  font-family: 'Newsreader', serif;

  color: $color-black;

  background-color: $color-white;
  background-image: url('/assets/bg.png');
  background-repeat: repeat;
  background-position: center center;
  box-shadow: $box-shadow;
}
