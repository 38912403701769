* {
  box-sizing: border-box;
}

[data-whatintent='mouse'] *:focus,
[data-whatintent='touch'] *:focus {
  outline: none;
}

html {
  height: 100%;

  scroll-behavior: smooth;
}

body {
  margin: 0px;

  font-smooth: always;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.screen-reader-only {
  position: absolute;

  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;

  overflow: hidden;
  clip: rect(0 0 0 0);

  border: 0;
}

input,
button,
textarea,
address {
  -webkit-appearance: none;
}

*::-webkit-media-controls-panel {
  display: none !important;

  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;

  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none !important;

  -webkit-appearance: none;
}
