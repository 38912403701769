@import '../../styles/utils/__utils';

.button {
  margin: $spacing-x-small;
  padding: $spacing-x-small $spacing-x-large;

  cursor: pointer;

  font-family: 'Open Sans', sans-serif;
  font-size: $font-size-medium;

  color: $color-black;
  text-decoration: none;

  border: none;
  border-radius: $spacing-xx-large;

  background-color: $color-green;

  transition: 0.2s;

  backface-visibility: hidden;
  &.secondary {
    background-color: $color-gray;
  }
  &.small {
    font-size: $font-size-small;
  }
  &:hover {
    transform: scale(1.05);
  }
}
